import {useEffect} from "react";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

function Yare() {

    const navigate = useNavigate();

    useEffect(() => {
        Cookies.set("coupon", "YARE");
        navigate("/");
    }, []);


    return (<></>)
}

export default Yare;
