import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
    AddressElement, CardCvcElement, CardExpiryElement, CardNumberElement,
    Elements, LinkAuthenticationElement, useElements, useStripe
} from '@stripe/react-stripe-js';
import {useNavigate} from 'react-router-dom';

// Stripe publishable key (hardcoded)

const Checkout = () => {
    const [clientSecret, setClientSecret] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false); // Track payment processing state
    const navigate = useNavigate();

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        fetch("https://api.whustle.com/create-payment-intent", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
        })
            .then(res => res.json())
            .then(data => setClientSecret(data.clientSecret));
    }, []);

    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);
    const [cardNumberError, setCardNumberError] = useState(null);
    const [cardExpiryError, setCardExpiryError] = useState(null);
    const [cardCvcError, setCardCvcError] = useState(null);

    const [cardNumberFocused, setCardNumberFocused] = useState(false);
    const [cardExpiryFocused, setCardExpiryFocused] = useState(false);
    const [cardCvcFocused, setCardCvcFocused] = useState(false);

    const [brand, setBrand] = useState(null);

    const handleChange = (event, setComplete, setError) => {
        if (event.elementType === "cardNumber") {
            console.log(event.brand);
            setBrand(event.brand);
        }
        setComplete(event.complete);
        setError(event.error ? event.error.message : null);
    };

    const elementStyle = {
        style: {
            base: {
                color: '#ffffff',
                fontSize: '10px',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                '::placeholder': {
                    color: '#888888',
                },
                iconColor: '#c4f0ff',
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
            },
            invalid: {
                color: '#fff',
                iconColor: '#fff',
            },
            complete: {
                color: '#ffffff',
            },
        },
    };

    const inputClass = (complete, error, focused) => {
        if (error) {
            return 'border-red-500';
        } else if (complete) {
            return 'border-gray-500';
        } else {
            return `border-gray-500 ${focused ? 'border-blue-500' : ''}`;
        }
    };

    const labelStyle = (focused, complete, error) => ({
        position: 'absolute',
        left: '1rem',
        top: '1.5rem',
        pointerEvents: 'none',
        transition: 'transform 0.3s ease, color 0.3s ease',
        transform: (focused || complete || error) ? 'translateY(-1.3rem)' : 'translateY(-1.2rem)',
        color: (focused || complete || error) ? '#6b7280' : '#6b7280',
        fontSize: (focused || complete || error) ? '8px' : '0rem',
    });

    const labelStyle2 = (focused, complete, error) => ({
        transition: 'transform 0.3s ease, color 0.3s ease',
        transform: (focused || complete || error) ? 'translateY(0rem)' : 'translateY(-0.2rem)',
    });

    const grayImage = (image) => {
        const baseStyles = "transition-all duration-300 ease-in-out";

        if (brand === "unknown") {
            return `${baseStyles} filter-none w-7 h-6`;
        }

        if (image === brand) {
            return `${baseStyles} filter-none w-8 h-7`;
        } else {
            return `${baseStyles} grayscale w-7 h-6`;
        }
    };

    const pay = async () => {
        if (!stripe || !elements || isProcessing) {
            return;
        }

        setIsProcessing(true);

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: "Test User"
                },
            },
            receipt_email: "lisacekmore@gmail.com"
        });

        setIsProcessing(false);

        if (result.error) {
            console.error(result.error.message);
        } else if (result.paymentIntent.status === 'succeeded') {
            console.log('Payment succeeded!');
            navigate('/success');
        }
    };

    return (
        clientSecret && (
            <div className={"grid grid-cols-2"}>
                <div className={"w-full h-full p-[32px]"}>
                    <div className={"space-y-4"}>
                        <div className={"space-y-2"}>
                            <h1 className={"text-l font-medium text-white"}>Contact</h1>
                            <LinkAuthenticationElement/>
                        </div>
                        <div>
                            <h1 className="text-l font-medium text-white pb-2">Payment</h1>
                            <div>
                                <div className={"bg-transparent"}>
                                    <div
                                        className="border border-red-500 rounded-t-md flex items-center justify-between p-3">
                                        <p className="flex-shrink-0 text-[12px]">Credit card</p>
                                        <div className="flex items-center space-x-2">
                                            <img
                                                src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/0169695890db3db16bfe.svg"
                                                alt="visa" className={grayImage("visa")}/>
                                            <img
                                                src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/ae9ceec48b1dc489596c.svg"
                                                alt="mastercard" className={grayImage("mastercard")}/>
                                            <img
                                                src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/37fc65d0d7ac30da3b0c.svg"
                                                alt="amex" className={grayImage("amex")}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#272727]">
                                    <div className={"p-3 space-y-2"}>
                                        <div
                                            className={` relative pb-2 pt-4 px-4 border rounded-md bg-transparent ${inputClass(cardNumberComplete, cardNumberError, cardNumberFocused)}`}>
                                            <label
                                                style={labelStyle(cardNumberFocused, cardNumberComplete, cardNumberError)}>
                                                Card Number
                                            </label>
                                            <div
                                                style={labelStyle2(cardNumberFocused, cardNumberComplete, cardNumberError)}
                                            >
                                                <CardNumberElement
                                                    options={elementStyle}
                                                    onChange={(event) => handleChange(event, setCardNumberComplete, setCardNumberError)}
                                                    onFocus={() => setCardNumberFocused(true)}
                                                    onBlur={() => setCardNumberFocused(false)}
                                                />
                                            </div>
                                        </div>

                                        {cardNumberError &&
                                            <p className="text-red-500 text-sm my-1">{cardNumberError}</p>}

                                        <div className={"flex space-x-2"}>
                                            <div
                                                className={`w-full relative pb-2 pt-4 px-4 border rounded-md bg-transparent ${inputClass(cardExpiryComplete, cardExpiryError, cardExpiryFocused)}`}>
                                                <label
                                                    style={labelStyle(cardExpiryFocused, cardExpiryComplete, cardExpiryError)}>
                                                    Expiration Date (MM / YY)
                                                </label>
                                                <div
                                                    style={labelStyle2(cardExpiryFocused, cardExpiryComplete, cardExpiryError)}
                                                >
                                                    <CardExpiryElement
                                                        options={elementStyle}
                                                        onChange={(event) => handleChange(event, setCardExpiryComplete, setCardExpiryError)}
                                                        onFocus={() => setCardExpiryFocused(true)}
                                                        onBlur={() => setCardExpiryFocused(false)}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className={`w-full relative pb-2 pt-4 px-4 border rounded-md bg-transparent  ${inputClass(cardCvcComplete, cardCvcError, cardCvcFocused)}`}>
                                                <label
                                                    style={labelStyle(cardCvcFocused, cardCvcComplete, cardCvcError)}>
                                                    Security Code
                                                </label>
                                                <div
                                                    style={labelStyle2(cardCvcFocused, cardCvcComplete, cardCvcError)}
                                                >
                                                    <CardCvcElement
                                                        options={elementStyle}
                                                        onChange={(event) => handleChange(event, setCardCvcComplete, setCardCvcError)}
                                                        onFocus={() => setCardCvcFocused(true)}
                                                        onBlur={() => setCardCvcFocused(false)}
                                                    />
                                                </div>
                                                {cardCvcError &&
                                                    <p className="text-red-500 text-sm my-1">{cardCvcError}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"p-3"}>
                                        <div
                                            className={` relative pb-2 pt-2 px-4 border rounded-md bg-transparent ${inputClass(cardNumberComplete, cardNumberError, cardNumberFocused)}`}>
                                            <label
                                                style={labelStyle(cardNumberFocused, cardNumberComplete, cardNumberError)}>
                                                Name on Card
                                            </label>
                                            <div
                                                style={labelStyle2(cardNumberFocused, cardNumberComplete, cardNumberError)}
                                            >
                                                <input placeholder={"Name on Card"}
                                                       className={"w-full bg-transparent text-[10px] border-none focus:outline-none text-white"}
                                                       type="text"/>
                                            </div>
                                        </div>
                                    </div>

                                    {cardExpiryError &&
                                        <p className="text-red-500 text-sm my-1">{cardExpiryError}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={"space-y-2"}>
                            <h1 className={"text-l font-medium text-white"}>Billing Address</h1>
                            <AddressElement options={{
                                mode: "billing", fields: {
                                    phone: "always"
                                }
                            }}/>
                        </div>
                        <button
                            onClick={pay}
                            disabled={isProcessing} // Disable button while processing
                            className={`bg-[#f5a623] text-white p-2 rounded-md w-full text-xl font-medium ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isProcessing ? 'Processing...' : 'Pay'}
                        </button>
                    </div>
                </div>

                <div className={"w-full"}>
                    <div className={"inline-flex p-8"}>
                        <div className="avatar h-[80px] w-[80px]">
                            <div className="h-16 rounded">
                                <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"/>
                            </div>

                            <div className={"relative rounded-full bg-red-500 h-4 right-3 bottom-2 text-center"}>
                                <p className={"text-xs"}>1</p>
                            </div>
                        </div>

                        <div>
                            <p className={"text-[9px] font-medium"}>Test Max</p>
                            <div className={"grid grid-cols-2 w-[220px]"}>
                                <p className={"text-[8px]"}>EU / Purple</p>
                                <p className={"text-[10px] text-right"}>Kč 977.00</p>
                            </div>
                            <p className={"text-[8px]"}>Předpokládané doručení: 29/08 nebo 03/09</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
        ;
};

export default Checkout;