function Service({title, label, img, tease, description, tags, labelStyle}) {
    return (
        <div>
            <div className="card bg-base-100 md:w-96 sm:w-fit shadow-xl my-4">
                <figure>
                    <img
                        className={"w-92"}
                        src={img}
                        alt="Shoes"/>
                </figure>
                <div className="card-body">
                    <h2 className="card-title">
                        {title}
                        <div
                            className={"badge text-white " + labelStyle}>{label}</div>
                    </h2>
                    <p>{tease}</p>
                    <div>
                        {description}
                    </div>
                    <div className="card-actions justify-end">
                        {tags.map((tag) => (
                            <div className="badge badge-outline">{tag}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service