import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

function Login() {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        email: '',
        password: '',
    });
    const {t} = useTranslation()

    useEffect(() => {
        //if logged in redirect to user
        if(Cookies.get('token')) {
            navigate('/user');
        }
    }, [navigate]);

    function login(e) {
        e.preventDefault()
        //https://api.whustle.com/login
        fetch('https://api.whustle.com/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(response => response.json())
            .then(data => {
                if(data.message === "Invalid credentials") {
                    toast(t("components.login.err.creds"), {
                        type: 'error',
                        theme: 'colored'
                    })
                    return;
                }
                Cookies.set('token', data.token);
                Cookies.set('email', data.email);
                Cookies.set('subscribed', data.subscribed);
                toast(t("components.login.scss.login"), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    icon: '🦄',
                    type: 'success',
                    theme: 'colored'
                });
                navigate('/user');
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function handleChange(e) {
        console.log(e.target.value);
        const {name, value} = e.target;
        setValues({...values, [name]: value});
    }

    return (
        <div>
            <Navbar/>
            <form className="space-y-4 w-[328px] mx-auto my-16">
                <h1 className={"font-bold"}>{t("components.login.title")}</h1>
                <div className="form-control">
                    <label htmlFor="email" className="label">
                        <span className="label-text">E-Mail</span>
                    </label>
                    <input onInput={handleChange} placeholder={t("components.login.email.placeholder")} type="email" id="email"
                           name="email"
                           value={values.email} className="input input-bordered"/>
                </div>

                <div className="form-control">
                    <label htmlFor="password" className="label">
                        <span className="label-text">{t("components.login.password")}</span>
                    </label>
                    <input onInput={handleChange} type="password" id="password" name="password"
                           value={values.password} placeholder={t("components.login.password")} className="input input-bordered"/>
                </div>
                <button onClick={login}
                        className="btn font-black text-white bg-gradient-to-r from-blue-600 via-blue-500 to-indigo-400">{t("components.login.button")}
                </button>
            </form>
            <Footer/>
        </div>
    );
}

export default Login;