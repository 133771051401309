import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function RefundPolicy() {
    const navigate = useNavigate();

    const {t} = useTranslation();

    return (
        <div>
            <Navbar/>
            <div className={"text-center mx-4"}>
                <h1 className={"font-bold text-3xl mb-4"}>{t("components.refund.title")}</h1>
                <p dangerouslySetInnerHTML={{__html: t("components.refund.content")}} className={"mb-12"}></p>
            </div>
            <Footer/>
        </div>
    );
}

export default RefundPolicy;