import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

function Register() {
    const navigate = useNavigate();
    const {email} = useParams();

    const [values, setValues] = useState({
        email: email ? email : '',
        password: '',
        passwordagain: '',
    });

    const {t} = useTranslation();

    function register(e) {
        e.preventDefault()
        if(values.password.length < 4){
            toast(t("register.err.password-too-short"), {
                type: "error"
            })
            return;
        }
        if (values.password !== values.passwordagain) {
            //toast
            toast(t("register.err.password.doesnt-match"), {
                type: "error"
            })
            return;
        }

        //https://api.whustle.com/login
        fetch('https://api.whustle.com/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === "Invalid credentials") {
                    toast(t("server.err.response"), {
                        type: "error"
                    })
                    return;
                }
                //if nnot 2OO return error
                if (data.message === "Unauthorized") {
                    toast(t("server.err.response"), {
                        type: "error"
                    })
                    return;
                }
                Cookies.set('token', data.token);
                Cookies.set('email', data.email);
                Cookies.set('subscribed', data.subscribed);
                navigate('/user');
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function handleChange(e) {
        console.log(e.target.value);
        const {name, value} = e.target;
        //if email return
        if (name === "email") {
            toast(
                t("register.err.cant-change.email"),
                {
                    type: "error"
                }
            );
            return;
        }
        setValues({...values, [name]: value});
    }

    return (
        <div>
            <Navbar/>
            <form className="space-y-4 w-[328px] mx-auto my-16">
                <h1 className={"font-bold"}>{t("register.title")}</h1>
                <div className="form-control">
                    <label htmlFor="email" className="label">
                        <span className="label-text">E-Mail</span>
                    </label>
                    <input disabled={true} onInput={handleChange} placeholder={"email@seznam.cz"} type="email"
                           id="email"
                           name="email"
                           value={values.email} className="input input-bordered"/>
                </div>

                <div className="form-control">
                    <label htmlFor="password" className="label">
                        <span className="label-text">{t("components.login.password")}</span>
                    </label>
                    <input onInput={handleChange} type="password" id="password" name="password"
                           value={values.password} placeholder={"Heslo"} className="input input-bordered"/>
                </div>

                <div className="form-control">
                    <label htmlFor="passwordagain" className="label">
                        <span className="label-text">{t("register.password-again")}</span>
                    </label>
                    <input onInput={handleChange} type="password" id="passwordagain" name="passwordagain"
                           value={values.passwordagain} placeholder={t("register.password-again")} className="input input-bordered"/>
                </div>
                <button onClick={register}
                        className="btn font-black text-white bg-gradient-to-r from-blue-600 via-blue-500 to-indigo-400">{t("register.register-button")}
                </button>
            </form>
            <Footer/>
        </div>
    );
}

export default Register;