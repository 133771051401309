import {useState} from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {useLocation} from "react-router-dom";
import demopng from "../../Assets/demo.png";
import {useTranslation} from "react-i18next";

function StockXBuy() {
    //input change
    const [values, setValues] = useState({
        id: '1',
        product: 'Sp5der',
        product_detail: 'PINK Hoodie',
        size: '1',
        total: '100.00',
        invoice_date: '2024-01-01',
    });

    const {t} = useTranslation();

    const [docs, setDocs] = useState([]);

    const location = useLocation();
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const demo = query.get('demo');


    const handleChange = (e) => {
        console.log(e.target.value);
        //if the change iis on id name
        const {name, value} = e.target;
        if (name === 'id') {
            setValues({...values, [name]: value});
            return;
        }

        if (demo) {
            //toast v ddemu nelze upravitt hodnoty
            toast(t("services.err.demo.image"), {
                type: 'error',
                theme: 'colored'
            })
            return;
        }
        setValues({...values, [name]: value});
    }

    function handleSubmit(e) {
        e.preventDefault(); // Prevent form submissios
        let file = document.getElementById('id').files[0];

        //check if file is selected
        if (!file) {
            toast(t("services.err.no-image"), {
                type: 'error',
                theme: 'colored'
            })
            return;
        }
        //chck if id enddds with png, jpg, jpeg or gif if not toast error unsupported image format
        let supportedFormats = ['png', 'jpg', 'jpeg', 'gif'];

        let ext = file.name.split('.').pop();
        if (!supportedFormats.includes(ext)) {
            toast(t("services.err.image.format"), {
                type: 'error',
                theme: 'colored'
            })
            return;
        }


    //convert image path in value.id to base64 image
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        let base64 = reader.result;
        let procRate = 0.0309; // Slightly more than 3%
        let taxRate = 0.2109; // Slightly more than 3%

        let totalBeforeFees = parseFloat(values.total) + 13.95;

        let proc = parseFloat((totalBeforeFees * procRate).toFixed(2));
        let tax = parseFloat((totalBeforeFees * taxRate).toFixed(2));

        let total = totalBeforeFees + tax + proc;
        total = parseFloat(total.toFixed(2));

        //try to fetch url
        toast.promise(fetch("https://api.whustle.com/buy?token=" + Cookies.get("token") + "&demo=" + demo + "&name=" + values.product, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({image: base64, demo: demo})
            })
                .then((data) => {
                    console.log("resp")
                    //get status code
                    if (data.status !== 200) {
                        toast(t("services.err.sub.ended"), {
                            type: 'error',
                            theme: 'colored'
                        })
                    } else {
                        console.log("success")
                        let url = 'https://api.whustle.com/buy?product=' + values.product + '&product_detail=' + values.product_detail + '&tax=' + tax + '&size=' + values.size + '&price=' + values.total + '&fee=' + proc + '&total=' + total + '&date=' + values.invoice_date + "&email=" + Cookies.get('email') + "&token=" +
                            Cookies.get('token') + "&demo=" + demo;
                        window.open(url, '_blank');
                        setDocs([<a className={"btn"} href={url} target={"_blank"}>{t("services.buttons.manually-open")}
                            ({values.product_detail})</a>]);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                }),
            {
                pending: t("services.states.0"),
                success: t("services.states.1"),
                error: t("services.states.2")
            }
        );
    }
}

return (
    <div>
        <Navbar/>
        <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/StockX_logo.svg/2560px-StockX_logo.svg.png"
            alt="logo" className="w-[200px] mx-auto"/>
        <form className="grid md:grid-cols-2 sm:grid-cols-1 space-x-4 md:mx-48 sm:mx-4">
            <div className="form-control w-full space-x-4">
                <div className="label">
                    <span className="label-text ml-4">{t("services.buttons.select-image")}</span>
                </div>
                <input onInput={handleChange} type="file" id="id" name="id"
                       className="file-input file-input-bordered w-full"/>
            </div>

            <div className="form-control">
                <label htmlFor="invoice_date" className="label">
                    <span className="label-text">{t("services.values.invoice-date")}</span>
                </label>
                <input onInput={handleChange} type="date" id="invoice_date" name="invoice_date"
                       value={values.invoice_date} className="input input-bordered"/>
            </div>

            <div className="form-control">
                <label htmlFor="total" className="label">
                    <span className="label-text">{t("services.values.total-price")}</span>
                </label>
                <input onInput={handleChange} type="number" id="total" name="total"
                       value={values.total} className="input input-bordered"/>
            </div>

            <div className="form-control">
                <label htmlFor="product" className="label">
                    <span className="label-text">{t("services.values.brand-name")}</span>
                </label>
                <input onInput={handleChange} type="text" id="product" name="product"
                       value={values.product}
                       className="input input-bordered"/>
            </div>
            <div className="form-control">
                <label htmlFor="product_detail" className="label">
                    <span className="label-text">{t("services.values.product")}</span>
                </label>
                <input onInput={handleChange} type="text" id="product_detail" name="product_detail"
                       value={values.product_detail}
                       className="input input-bordered"/>
            </div>

            <div className="form-control">
                <label htmlFor="size" className="label">
                    <span className="label-text">{t("services.values.size")}</span>
                </label>
                <input onInput={handleChange} type="text" id="size" name="size" value={values.size}
                       className="input input-bordered"/>
            </div>
        </form>
        <div className="form-control mt-4 w-3/6 m-auto mb-8">
            <button onClick={handleSubmit}
                    className="btn font-bold  bg-gradient-to-r from-red-600 via-orange-500 to-red-400 text-white">🚀
                {t("services.buttons.generate")}
            </button>
            {docs.length > 0 ? <div><h1>{t("services.buttons.not-opened")}</h1> {docs}</div> : null}
        </div>
        <Footer/>
    </div>
);
}

export default StockXBuy;
