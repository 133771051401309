import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import gg from "../Assets/logo69.png";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function Navbar() {
    const navigate = useNavigate();
    const [maintenance, setMaintenance] = useState(false);
    const [message, setMessage] = useState("");

    const {t} = useTranslation();

    useEffect(() => {
        fetch("https://api.whustle.com/maintenance").then(result => {
            if (result.status !== 200) return;
            result.json().then(data => {
                setMaintenance(data.maintenance.is_enabled);
                setMessage(data.maintenance.message);
            })
        }).catch(() => {
            setMaintenance(true);
            setMessage(t("menu.offline"))
        })
    }, []);

    function logout() {
        navigate("/login");
    }

    return (<div>
        {maintenance ? <div className={"text-center bg-[#ff4000]"}>⌛ <span className={"text-white font-bold"}>{message}</span></div> : null}
        <div className="navbar bg-base-100 md:px-56 sm:px-4 bg-black mb-12">
            <div className="navbar-start">
                <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h8m-8 6h16"/>
                        </svg>
                    </div>
                    <ul
                        tabIndex={0}
                        className="bg-black menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                        <li>
                            <button onClick={() => navigate("/")}>{t("menu.home")}</button>
                        </li>
                        {Cookies.get("token") ?
                            <li>
                                <button onClick={() => navigate("/ucet")}>{t("menu.account")}</button>
                            </li> : null}
                        {!Cookies.get("token") ? <li>
                            <button onClick={() => navigate("/funkce")}>{t("menu.tools")}</button>
                        </li> : null}
                        <li>
                            <button onClick={() => navigate("/resell")}>{(t("menu.affiliates"))}</button>
                        </li>
                    </ul>
                </div>
                <img onClick={() => navigate("/")} src={gg} className={"w-16"}/>
            </div>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1">
                    <li>
                        <button onClick={() => navigate("/")}>🏠 {t("menu.home")}</button>
                    </li>
                    {!Cookies.get("token") ?
                        <li>
                            <button onClick={() => navigate("/funkce")}>⭐️ {t("menu.tools")}</button>
                        </li> : null}
                    <li>
                        <button onClick={() => navigate("/resell")}>🛒 {t("menu.affiliates")}</button>
                    </li>
                    {Cookies.get("token") ?
                        <li>
                            <button onClick={() => navigate("/ucet")}>⭐️ {t("menu.account")}</button>
                        </li> : null}
                </ul>
            </div>
            <div className="navbar-end space-x-4">
                {Cookies.get("token") ? <button onClick={() => navigate("/user")}
                                                className="btn font-black text-white bg-gradient-to-r from-blue-600 via-blue-500 to-indigo-400">🛠
                     {t("menu.feature")}</button> :
                    <div className={"inline-flex space-x-4"}>
                        <button onClick={() => navigate("/login")}
                                className="btn font-black text-white bg-gradient-to-r from-blue-600 via-blue-500 to-indigo-400">🔑
                            {t("components.login.button")}
                        </button>
                        <button onClick={() => navigate("/user?demo=true")}
                                className="hidden md:block btn font-black bg-gradient-to-r from-red-600 via-orange-500 to-red-400 text-white">☄️
                            {t("menu.free-trial")}
                        </button>
                    </div>}

            </div>
        </div>
    </div>)
}

export default Navbar;