import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {useTranslation} from "react-i18next";

function ResellShopy() {
    const {t} = useTranslation();

    return (
        <div className="w-full">
            <Navbar/>
            <div className="md:mx-auto mx-8 md:max-w-screen-md overflow-x-hidden">
                <div className="">
                    <div className="flex flex-col lg:flex-row items-start mb-8">
                        <div className="flex-shrink-0 mr-4">
                            <img
                                src="https://v2.whustle.com/cdn/yar.jpg"
                                className="w-32 rounded-lg shadow-2xl mt-4"
                            />
                        </div>
                        <div>
                            <h1 className="text-4xl md:text-5xl font-bold">YarEcom</h1>
                            <p className="py-6">
                                Tento obchod se specializuje na prodej NEJLEPŠÍCH dodavetelů různých produktů!
                            </p>
                            <a
                                href="https://yarecom.gumroad.com"
                                target="_blank"
                                className="btn"
                            >
                                🏆 Navštívit
                            </a>
                        </div>
                    </div>
                </div>

                <div className="">
                    <div className="flex flex-col lg:flex-row items-start mb-8">
                        <div className="flex-shrink-0 mr-4">
                            <img
                                src="https://api.resellerheaven.eu/cdn/rhlogo.png"
                                className="w-32 rounded-lg shadow-2xl mt-4"
                            />
                        </div>
                        <div>
                            <h1 className="text-4xl md:text-5xl font-bold">ResellerHeaven</h1>
                            <p className="py-6">{t("stores.0.description")}</p>
                            <a
                                href="https://resellerheaven.eu"
                                target="_blank"
                                className="btn"
                            >
                                {t("stores.0.button-text")}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mb-8">
                    <div className="flex flex-col lg:flex-row items-start">
                        <div className="flex-shrink-0 mr-4">
                            <img
                                src="https://vendorsworld.us/cdn/shop/files/Vendors_uzsi.png?v=1729112005&width=90"
                                className="w-32 rounded-lg shadow-2xl"
                            />
                        </div>
                        <div>
                            <h1 className="text-4xl md:text-5xl font-bold">VendorsWorld</h1>
                            <p className="py-6">{t("stores.1.description")}</p>
                            <a
                                href="https://vendorsworld.de"
                                target="_blank"
                                className="btn"
                            >
                                {t("stores.1.button-text")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>

    )
}

export default ResellShopy;
