import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";


function Payment() {


    return (
        <div>
            <Navbar/>
            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
            <stripe-pricing-table pricing-table-id="prctbl_1PaST6FqQPKh8TUqj066CIin"
                                  publishable-key="pk_live_51Ozc6fFqQPKh8TUqUUqKnd5DnZdakxxj67nxBO8ImV1V5fzZWARjGMr0xOrnXYIJn1inum5JGRasaahjtat8j9gJ00z7sBXc7z">
            </stripe-pricing-table>
            <Footer/>
        </div>
    );
}

export default Payment;