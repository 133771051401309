import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";

function Paid() {
    //get /paid/:id
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        //fetch data post
        fetch('https://api.whustle.com/paid/' + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((data) => {
                //get status code
                if (data.status !== 200) {
                    console.log("Error");
                } else {
                    console.log("Success");
                    //insert into queue id, email
                    data.json().then(data => {
                        const email = data.email;
                        const hasAccount = data.hasAccount;
                        if (hasAccount) {
                            //if logged in redirect to user
                            if (Cookies.get("token")) {
                                Cookies.set("subscribed", 1);
                                navigate("/ucet")
                            } else {
                                navigate("/login")
                            }
                        } else {
                            navigate("/register/" + email)
                        }
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [id, navigate])

    const {t} = useTranslation();


    return (
        <div>
            <Navbar/>
            <div className={"h-96"}>
                <h1 className={"text-[30px] mx-auto text-center"}>{t("paid")}</h1>
                <h1 className={"text-[80px] mx-auto text-center"}>🎉</h1>
            </div>
            <Footer/>
        </div>
    );
}

export default Paid;