import {useState} from "react";
import logo from '../../Assets/logo69.png';
import axios from 'axios';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Datart() {
    const location = useLocation();

    // Function to parse query parameters
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const demo = query.get('demo');
    const {t} = useTranslation();

    //input change
    const [values, setValues] = useState({
        name: 'John Doe',
        invoice_date: '2024-01-01',
        product: '1',
        quantity: '1',
        base_price: '100.00',
        email: 'test@gmail.com',
        serial_number: "123",
        phone: '+420 123 456 789',
        street: 'Main St, Springfield',
        house_number: '123',
        postal_code: 'Springfield 12345,',
        store_location_name: 'dbrno',
        //pdf_location faktura_timestamp.pdf
    });

    const [docs, setDocs] = useState([]);


    const handleChange = (e) => {
        if (demo) {
            //toast v ddemu nelze upravitt hodnoty
            toast("Nelze upravovat hodnoty v demo režimu", {
                type: 'error',
                theme: 'colored'
            })
            return;
        }

        const {name, value} = e.target;
        if (name === `product`) {
            switch (value) {
                case "1":
                    setValues({...values, base_price: '6290.00'});
                    break;
                case "2":
                    setValues({...values, base_price: '26851.24'});
                    break;
                case "3":
                    setValues({...values, base_price: '3793.39'});
                    break;
                case "4":
                    setValues({...values, base_price: '3793.39'});
                    break;
                case "5":
                    setValues({...values, base_price: '3676.86'});
                    break;
                default:
                    setValues({...values, base_price: '100.00'});
            }
        }

        setValues({...values, [name]: value});
    }

    function handleSubmit(e) {
        e.preventDefault(); // Prevent form submission
        let replacements = {
            name: values.name,
            invoice_date: values.invoice_date,
            product_code: '123456',
            product: values.product,
            quantity: '1',
            base_price: values.base_price,
            dph_cut: '21',
            dph_zaklad: values.base_price,
            price_with_dph: (values.base_price * 1.21).toFixed(2) + 39,
            serial_number: values.serial_number,
            short_date_time: new Date(values.invoice_date).toLocaleString('cs-CZ'),
            email: values.email,
            phone: values.phone,
            street: values.street,
            house_number: values.house_number,
            postal_code: values.postal_code,
            postal: values.postal_code,
            store_location_name: values.store_location_name,
            store_location_address: 'Main St, Springfield',
            store_phone: '+420 123 456 789',
            store_email: 'test@gmail.com',
            pdf_location: "faktura_" + new Date().getTime() + ".pdf"
        }

        console.log(values.store_location_name)
        console.log(values.product)

        let date = values.invoice_date;
        //format to dd. mm. yyyy
        let dateFormatted = date.split('-').reverse().join('.');

// Split the date string into Components
        let dateComponents = date.split('-');

// Rearrange the Components to the format dd/mm/yy
        let day = dateComponents[2];
        let month = dateComponents[1];
        let year = dateComponents[0].slice(2); // Get the last two digits of the year

// Combine the Components into the desired format
        let df = `${day}/${month}/${year}`;

// Get the current time in the format hh:mm:ss
        let timeFormatted = new Date().toLocaleTimeString('cs-CZ');

// Combine the date and time into the final formats
        let dateFormatted2 = `${df} ${timeFormatted}`;
        replacements.invoice_date = dateFormatted;
        replacements.short_date_time = dateFormatted2;

        switch (values.store_location_name) {
            case "dbrno":
                replacements.store_location_name = "1520 Datart Elektro, Brno, OC Vaňkovka";
                replacements.store_location_address = "Ve Vaňkovce, Brno 2, 60200";
                replacements.store_phone = "+420 725568699";
                replacements.store_email = "brnovankovka@datart.cz";
                break;
            case "dpraha":
                replacements.store_location_name = "1320 Datart Elektro, Praha 5, OC Nový Smíchov";
                replacements.store_location_address = "Plzeňská 8, Praha 5, 15000";
                replacements.store_phone = "+420 577055751";
                replacements.store_email = "PrahaAndel@datart.ccz";
                break;
            default:
                replacements.store_location_name = "1520 Datart Elektro, Brno, OC Vaňkovka";
                replacements.store_location_address = "Ve Vaňkovce, Brno 2, 60200";
                replacements.store_phone = "+420 725568699";
                replacements.store_email = "brnovankovka@datart.cz";
        }

        switch (values.product) {
            case "1":
                replacements.product = "Apple AirPods Pro 2023 s MagSafe (USB‑C)";
                replacements.product_code = "APPMTJV3ZMA";
                break;
            case "3":
                replacements.product = "Apple AirPods Pro 2021 s MagSafe (Lightning)";
                replacements.product_code = "APPMME73ZMA";
                break;
            case "4":
                replacements.product = "Apple AirPods Pro 2021";
                replacements.product_code = "APPMME73ZMAZC2";
                break;
            case "5":
                replacements.product = "Apple AirPods 2022 s Lightning nabíjecím pouzdrem";
                replacements.product_code = "APPMPNY3ZMA";
                break;
            case "6":
                replacements.product = "Apple AirPods (2019)";
                replacements.product_code = "APPMV7N2ZMA";
                break;
            case "7":
                replacements.product = "Přenosný reproduktor JBL FLIP 6 černý";
                replacements.product_code = "JBLFLIP6BLKEU";
                break;
            case "8":
                replacements.product = "Apple AirPods Max - temně inkoustové";
                replacements.product_code = "APPMGYH3ZMA";
                break;
            case "9":
                replacements.product = "Apple AirPods Max - Silver";
                replacements.product_code = "APPMGYJ3ZMA";
                break;
            case "10":
                replacements.product = "Fén Dyson Supersonic HD07 šedá/fuchsiová";
                replacements.product_code = "DYSDS38673201";
                break;
            case "11":
                replacements.product = "Fén Dyson Supersonic HD07 šedá/fuchsiová";
                replacements.product_code = "DYSDS38673201";
                break;
            case "12":
                replacements.product = "Apple Watch Series 9 GPS 45mm černé";
                replacements.product_code = "APPMR993QCA";
                break;
            case "13":
                replacements.product = "Sluchátka Apple AirPods 4";
                replacements.product_code = "APPMXP63ZMA";
                break;
            case "2":
                replacements.product = "Apple iPhone 15 Pro Max 256GB Black Titanium";
                replacements.product_code = "APPMU773SXA";
                break;
            default:
                replacements.product = "AirPods";
        }


        //https://api.resellerheaven.store/invoice axios * cors
        toast.promise(
            axios.post('https://api.whustle.com/invoice', {
                    replacements: replacements,
                    token: Cookies.get('token'),
                    email: Cookies.get('email'),
                    demo: demo
                }, {
                    headers: {
                        //fix this  No 'Access-Control-Allow-Origin' header is present on the requested resource.
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(res => {
                window.open('https://api.whustle.com/invoice/' + replacements.pdf_location + "?email=" + Cookies.get("email") + "&demo=" + demo, '_blank');
                setDocs([<a className={"btn"}
                            href={"https://api.whustle.com/invoice/" + replacements.pdf_location + "?email=" + Cookies.get("email") + "&demo=" + demo}
                            target={"_blank"}>{t("services.buttons.manually-open")} ({replacements.pdf_location})</a>]);
            }),
            {
                pending: t("services.states.0"),
                success: t("services.states.1"),
                error: t("services.states.2")
            }
        );
    }

    return (
        <div>
            <Navbar/>
            <div className={"space-y-4 mx-auto my-16"}>
                <h1 className="text-3xl text-center">DATART FAKTURA</h1>
                <form className="grid md:grid-cols-2 sm:grid-cols-1 space-x-4 md:mx-48 sm:mx-4">
                    <div className="form-control ml-4">
                        <label htmlFor="name" className="label">
                            <span className="label-text">{t("services.values.full-name")}</span>
                        </label>
                        <input onInput={handleChange} type="text" id="name" name="name" value={values.name}
                               className="input input-bordered"/>
                    </div>

                    <div className="form-control">
                        <label htmlFor="invoice_date" className="label">
                            <span className="label-text">{t("services.values.invoice-date")}</span>
                        </label>
                        <input onInput={handleChange} type="date" id="invoice_date" name="invoice_date"
                               value={values.invoice_date} className="input input-bordered"/>
                    </div>

                    <div className="form-control">
                        <label htmlFor="product" className="label">
                            <span className="label-text">{t("services.values.product")}</span>
                        </label>
                        <select onInput={handleChange} id="product" name="product" value={values.product}
                                className="select select-bordered">
                            <option value="1">AirPods Pro 2023 s MagSafe (USB‑C) (Pro 2)</option>
                            <option value="3">AirPods Pro 2021 s MagSafe (Pro 2)</option>
                            <option value="4">AirPods Pro 2021 (Pro 1)</option>
                            <option value="5">AirPods 2022 (Gen 3)</option>
                            <option value="6">AirPods (2019)</option>
                            <option value="13">Sluchátka Apple AirPods (Gen 4)</option>
                            <option value="7">JBL FLIP 6 černý</option>
                            <option value="11">JBL Charge 5 černý</option>
                            <option value="8">AirPods Max (Black)</option>
                            <option value="9">AirPods Max (Silver)</option>
                            <option value="10">Dyson Supersonic HD07 šedá/fuchsiová</option>
                            <option value="2">iPhone 15 Pro Max 256GB Black Titanium</option>
                            <option value="12">Apple Watch Series 9 GPS 45mm - černé</option>
                        </select>
                    </div>

                    <div className="form-control">
                        <label htmlFor="serial_number" className="label">
                            <span className="label-text">{t("services.values.serial")}</span>
                        </label>
                        <input onInput={handleChange} type="text" id="serial_number" name="serial_number"
                               value={values.serial_number} className="input input-bordered"/>
                    </div>

                    <div className="form-control">
                        <label htmlFor="base_price" className="label">
                            <span className="label-text">{t("services.values.price-without-dph")}</span>
                        </label>
                        <input onInput={handleChange} type="number" id="base_price" name="base_price"
                               value={values.base_price} className="input input-bordered"/>
                        <a href={"https://kalkulackadph.cz/"} className="pl-1 pt-1 label-text-alt underline"
                           target={"_blank"}>🖩
                            Kalkulačka DPH</a>
                    </div>

                    <div className="form-control">
                        <label htmlFor="email" className="label">
                            <span className="label-text">Email</span>
                        </label>
                        <input onInput={handleChange} type="text" id="email" name="email" value={values.email}
                               className="input input-bordered"/>
                    </div>

                    <div className="form-control">
                        <label htmlFor="phone" className="label">
                            <span className="label-text">{t("services.values.phone-number")}</span>
                        </label>
                        <input onInput={handleChange} type="text" id="phone" name="phone" value={values.phone}
                               className="input input-bordered"/>
                    </div>

                    <div className="form-control">
                        <label htmlFor="street" className="label">
                            <span className="label-text">{t("services.values.street")}</span>
                        </label>
                        <input onInput={handleChange} type="text" id="street" name="street" value={values.street}
                               className="input input-bordered"/>
                    </div>

                    <div className="form-control">
                        <label htmlFor="house_number" className="label">
                            <span className="label-text">{t("services.values.house-number")}</span>
                        </label>
                        <input onInput={handleChange} type="text" id="house_number" name="house_number"
                               value={values.house_number} className="input input-bordered"/>
                    </div>

                    <div className="form-control">
                        <label htmlFor="postal_code" className="label">
                            <span className="label-text">{t("services.values.postal")}</span>
                        </label>
                        <input onInput={handleChange} type="text" id="postal_code" name="postal_code"
                               value={values.postal_code} className="input input-bordered"/>
                    </div>

                    <div className="form-control">
                        <label htmlFor="store_location_name" className="label">
                            <span className="label-text">{t("services.values.shop-location")}</span>
                        </label>
                        <select onInput={handleChange} id="store_location_name" name="store_location_name"
                                value={values.store_location_name} className="select select-bordered">
                            <option value="dbrno">DATART - Brno</option>
                            <option value="dpraha">DATART - Praha</option>
                        </select>
                    </div>
                </form>
                <div className="form-control mt-4 w-3/6 m-auto">
                    <button onClick={handleSubmit}
                            className="btn font-bold  bg-gradient-to-r from-red-600 via-orange-500 to-red-400 text-white">🚀
                        {t("services.buttons.generate")}
                    </button>
                    {docs.length > 0 ? <div><h1>{t("services.buttons.not-opened")}</h1> {docs}</div> : null}
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Datart;
