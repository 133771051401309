import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import qr from "../Assets/qr.png";
import ucto from "../Assets/ucto.png";
import sn from "../Assets/sn.png";
import phone from "../Assets/phone.jpg";
import prdj from "../Assets/prdj.png";
import { useTranslation } from "react-i18next";

function Funkce() {
    const { t } = useTranslation();

    return (
        <div>
            <Navbar />
            <div className="grid md:grid-cols-2 grid-cols-1 justify-center w-full h-full mb-12">
                <div className="text-center md:ml-64 ml-5 md:m-0 m-5">
                    <h1 className="text-left font-bold text-3xl mb-4">{t('features.datart.title')}</h1>
                    <p className="text-left">{t('features.datart.items.0')}</p>
                    <img src={qr} className={"w-96"} />
                    <p className="text-left">{t('features.datart.items.1')}</p>
                    <img src={ucto} className={"w-96"} />
                    <p className="text-left">{t('features.datart.items.2')}</p>
                    <img src={sn} className={"w-96"} />
                    <p className="text-left">{t('features.datart.items.3')}</p>
                    <p className="text-left">{t('features.datart.items.4')}</p>
                    <p className="text-left">{t('features.datart.items.5')}</p>
                    <p className="text-left">{t('features.datart.items.6')}</p>
                    <img src={prdj} className={"w-96"} />
                </div>
                <div className="text-center md:ml-16 ml-5 md:m-0 m-5">
                    <h1 className="text-left font-bold text-3xl mb-4">{t('features.stockx.title')}</h1>
                    <p className="text-left">{t('features.stockx.items.0')}</p>
                    <p className="text-left">{t('features.stockx.items.1')}</p>
                    <p className="text-left">{t('features.stockx.items.2')}</p>
                    <p className="text-left">{t('features.stockx.items.3')}</p>
                    <p className="text-left">{t('features.stockx.items.4')}</p>
                    <p className="text-left">{t('features.stockx.items.5')}</p>
                    <img src={phone} className={"w-96"} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Funkce;
