import {useTranslation} from "react-i18next";

function Card({title, description, image, onClick}) {

    const {t} = useTranslation();

  return (
      <div className="card card-compact bg-base-100 md:w-72 sm:w-fit shadow-xl mb-4">
          <figure>
              <img
                  src={image}
                  alt="Shoes"/>
          </figure>
          <div className="card-body">
              <h2 className="card-title">{title}</h2>
              <p>{description}</p>
              <div className="card-actions justify-end">
                  <button onClick={onClick} className="btn">{t("components.user.open.tool.button")}</button>
              </div>
          </div>
      </div>
  );
}

export default Card;