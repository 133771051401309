import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Cookies from "js-cookie";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import getUserLocale from "get-user-locale";

function Ucet() {

    const [confirmModal, setConfirmModal] = useState(false);

    const [usage, setUsage] = useState(0);
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        //if not logged iin redirect to /login
        if (!Cookies.get("token")) {
            navigate("/login");
            return;
        }

        axios.get("https://api.whustle.com/usage?token=" + Cookies.get("token")).then(result => {
            if (result.status !== 200) return;
            setUsage(result.data.usage);
        }).catch(() => {
        })
    }, []);

    function logout() {
        Cookies.remove("token");
        Cookies.remove("email");
        window.location.href = "/";
    }

    function cancelSubscription() {
        axios.post("https://api.whustle.com/cancel?email=" + Cookies.get("email") + "&token=" + Cookies.get("token"))
            .then((data) => {
                if (data.status !== 200) {
                    alert(t("server.err.response"));
                } else {
                    alert(t("server.scss.cancel-sub"));
                    Cookies.set("subscribed", 0);
                    window.location.href = "/ucet";
                }
            }).catch((err) => {
            console.log(err);
        });
    }


    return (
        <div>
            <Navbar/>
            <div>
                <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">{t("components.account.confirm.cancel.sub")}</h3>
                        <p className="py-4">{t("components.account.trial.end.warning")}</p>
                        <div className="modal-action">
                            <form className={"space-x-4"} method="dialog">
                                {/* if there is a button in form, it will close the modal */}
                                <button onClick={cancelSubscription} className="btn bg-red-500 text-white">{t("components.account.confirm.button")}
                                </button>
                                <button className="btn bg-green-500 text-white">{t("components.account.cancel.button")}</button>
                            </form>
                        </div>
                    </div>
                </dialog>
            </div>
            <div className={"grid grid-cols-1 items-center justify-center mb-8"}>
                <h1 className={"font-bold w-full text-center text-3xl"}>{t("components.account.user")}</h1>
                <div className={
                    "w-[300px] justify-self-center flex flex-col items-center"
                }>
                    <p>{t("components.account.your.email")}: <b>{Cookies.get("email")}</b></p>
                    <p className={"mb-8"}>{t("components.account.usage")}: <b>{usage}</b></p>
                    <div className={"grid grid-cols-1"}>
                        <button onClick={logout} className={"w-32 btn bg-red-500 text-white mt-4"}>{t("components.account.logout")}</button>
                        {Cookies.get("subscribed") === "0" || Cookies.get("subscribed") === 0 ?
                            <div>
                                <a href={getUserLocale() === "cs" ? "https://buy.stripe.com/28obMH0oreSn1J64gm" : "https://buy.stripe.com/28obMH0oreSn1J64gm"}
                                   className="md:pt-4 pt-0 btn font-black bg-gradient-to-r from-red-600 via-orange-500 to-red-400 text-white">☄️
                                    {t("components.account.subscribe")}
                                </a>
                            </div> : <div>
                                <button className="w-32 btn bg-orange-500 text-white mt-4"
                                        onClick={() => document.getElementById('my_modal_5').showModal()}>
                                    {t("components.account.cancel.sub")}
                                </button>
                            </div>}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Ucet;