import {useEffect} from "react";
import axios from "axios";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

function Rey() {

    useEffect(() => {
        axios.get("https://api.whustle.com/rey").then(result => {
            //redirect to result.data.url
            window.location.href = result.data.url;
        }).catch(() => {})
    }, []);

    return (
        <div>
            <Navbar/>
            <div className="flex items-center justify-center h-fit my-36">
                <span className="w-[100px] loading loading-dots loading-lg text-center"></span>
            </div>
            <Footer/>
        </div>
    )
}

export default Rey;