import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function Faktury() {
    return (
        <div>
            <Navbar/>
            <div className={"items-center justify-center w-full"}>
                <ul className={"text-center"}>
                    <li>✅ Generujeme QR Kódy</li>
                </ul>
            </div>
            <Footer/>
        </div>
    )
}

export default Faktury;