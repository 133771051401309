import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Card from "../../Components/Card";
import Cookies from "js-cookie";
import {useEffect} from "react";
import datart from "../../Assets/datart.png";
import stockx from "../../Assets/stockx.png";
import alza from "../../Assets/alza.png";
import alza2 from "../../Assets/alza2.jpg";
import {useTranslation} from "react-i18next";

function User() {
    const navigate = useNavigate();
    const location = useLocation();

    // Function to parse query parameters
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const demo = query.get('demo');

    useEffect(() => {
        if (!Cookies.get("token") && !demo) {
            console.log(demo)
            console.log("redirecting")
            navigate("/login");
        }
    }, [demo, navigate]);

    const {t} = useTranslation();


    return (
        <div className={"w-full"}>
            <Navbar/>
            <div className={"sm:mx-0 md:mx-48"}>
                <div className="ml-6 md:ml-16 inline-flex space-x-8">
                    <div>
                        <h1>{t("components.user.greetings")}</h1>
                        <h1>{t("components.user.select.tool")}</h1>
                    </div>
                    {!demo ?
                    <div>
                        <button className={"btn bg-red-500 text-white"} onClick={() => {
                            Cookies.remove("token")
                            Cookies.remove("email")
                            navigate("/login")
                        }}>{t("components.account.logout")}</button>
                    </div> : null}
                </div>
                <div className={"grid sm:grid-cols-1 md:grid-cols-3 sm:ml-0 md:ml-16 m-5"}>
                    <Card title={"StockX Sell"}
                          description={t("components.user.cards.0")}
                          image={stockx}
                          onClick={() =>navigate("/sell" + (demo ? ("?demo=" + demo) : ""))}/>
                    <Card title={"StockX Buy"}
                          description={t("components.user.cards.1")}
                          image={stockx}
                          onClick={() => navigate("/buy" + (demo ? ("?demo=" + demo) : ""))}/>
                    <Card title={"DATART CZ Faktura"}
                          description={t("components.user.cards.2")}
                          image={datart}
                          onClick={() => navigate("/datart" + (demo ? ("?demo=" + demo) : ""))}/>
                    <Card title={"ALZA SK Faktura"}
                          description={t("components.user.cards.3")}
                          image={alza2}
                          onClick={() => navigate("/alzask" + (demo ? ("?demo=" + demo) : ""))}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default User;